import React from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route
} from 'react-router-dom';
import HorarioClases from './components/horario de clases/HorarioClases';
import Layout from './Layout/Layout';
import { ContextProvider } from './context/ContextProvider';

import './App.scss';

const App = () => {
    return (
        <ContextProvider>
            <Router>
                <Routes>
                    <Route element={<Layout />}>
                        <Route exac path='/' element={<HorarioClases />}/>
                    </Route>
                </Routes>
            </Router>
        </ContextProvider>
    )
}

export default App
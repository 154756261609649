import React, { useCallback, useRef } from 'react';
import { toPng } from 'html-to-image';
import BannerHorario from '../../images/banner_horario.png';
import Btn from '../../images/btn_descargar.png';


import './HorarioClases.scss';

const HorarioClases = () => {
  // const descargar = () => {
  //   window.print();
  //   console.log('Descargar imagen');
  // }

  const ref = useRef(null);

  const descargar = useCallback(() => {
    if (ref.current === null) {
      return
    }

    toPng(ref.current, { cacheBust: true, height:700})
      .then((dataUrl) => {
        const link = document.createElement('a')
        link.download = 'horario-clases.png'
        link.href = dataUrl
        link.click()
      })
      .catch((err) => {
        console.log(err)
      })
  }, [ref])
  return (
    <div className='horario_completo'>
      <div className='div_horario' ref={ref}>
          <img src={BannerHorario} alt='imagen'/>
          <div className='div_column'>
            <div className='column_hora'>
              <div className='div_title'>
                <p>Hora</p>
              </div>              
              <input type='text'></input>
              <input type='text'></input>
              <input type='text'></input>
              <input type='text'></input>
              <input type='text'></input>
              <input type='text'></input>
              <input type='text'></input>
            </div>
            <div className='column_days'>
              <div className='div_title'>
                <p>Lunes</p>
              </div> 
              <input type='text'></input>
              <input type='text'></input>
              <input type='text'></input>
              <input type='text'></input>
              <input type='text'></input>
              <input type='text'></input>
              <input type='text'></input>
            </div>
            <div className='column_days'>
              <div className='div_title'>
                <p>Martes</p>
              </div> 
              <input type='text'></input>
              <input type='text'></input>
              <input type='text'></input>
              <input type='text'></input>
              <input type='text'></input>
              <input type='text'></input>
              <input type='text'></input>
            </div>
            <div className='column_days'>
              <div className='div_title'>
                <p>Miercoles</p>
              </div> 
              <input type='text'></input>
              <input type='text'></input>
              <input type='text'></input>
              <input type='text'></input>
              <input type='text'></input>
              <input type='text'></input>
              <input type='text'></input>
            </div>
            <div className='column_days'>
              <div className='div_title'>
                <p>Jueves</p>
              </div> 
              <input type='text'></input>
              <input type='text'></input>
              <input type='text'></input>
              <input type='text'></input>
              <input type='text'></input>
              <input type='text'></input>
              <input type='text'></input>
            </div>
            <div className='column_days'>
              <div className='div_title'>
                <p>Viernes</p>
              </div> 
              <input type='text'></input>
              <input type='text'></input>
              <input type='text'></input>
              <input type='text'></input>
              <input type='text'></input>
              <input type='text'></input>
              <input type='text'></input>
            </div>
          </div>        
      </div>
      <div className='conten_btn'>
        <img src={Btn} alt='imagen boton' onClick={() => descargar()}/>
      </div>
    </div>
  )
}

export default HorarioClases